// splat route necessary to render 404 page with i18next

// eslint-disable-next-line custom-rules/authenticate-endpoints
export function loader() {
	// eslint-disable-next-line @typescript-eslint/only-throw-error
	throw new Response("Not found", { status: 404 });
}

export default function NotFound() {
	return null;
}
